import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { Spin, Typography } from 'antd';

const SignedAWSURL: React.FC<{
  text: string | null;
  s3_key: string;
  level?: 'private' | 'protected' | 'public';
  warn?: boolean;
}> = ({ text, level, s3_key, warn }) => {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    Storage.get(s3_key, {
      level: level || 'private',
      bucket: process.env.REACT_APP_PRIVATE_STORAGE_BUCKET,
    }).then((result) => {
      setUrl(result);
    });
  }, [level, s3_key]);

  return (
    <Spin spinning={!url}>
      <Typography.Link
        href={url || ''}
        target="_blank"
        rel="noopener noreferrer"
        type={warn ? 'danger' : undefined}
      >
        {text}
      </Typography.Link>
    </Spin>
  );
};

export default SignedAWSURL;
